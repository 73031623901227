<template>
    <div class="  is-fullheight sticky-sidebar" >
      <aside class="menu column  aside   is-fullheight" >
       <p class="menu-label">
Bookings
      </p>
       <ul class="menu-list">
         <li>
           <a class="">General</a>
           <ul>
             <li @click="activeMenuLink = 'tickets'" ><router-link to="/tickets"  :class="{'is-active':activeMenuLink === 'tickets'}">My Tickets</router-link></li>
             
           </ul>
         </li>

         
         
       </ul>
       
     </aside>
     
    </div>
 </template>
 
 <script>
 export default {
   name: 'SideBarTickets',
   components: {
   },
   data() {
     return { activeMenuLink: null }
   },
   mounted() {
     this.activeMenuLink = this.$route.path.substring(1)
     //this.activeMenuLink = this.$route.path
     console.log(this.activeMenuLink)
   }
 }
 
 </script>